// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import 'photo-editor.css';

:root {
  --ion-color-warning: #ff8300;
  --ion-color-warning-rgb: 0,102,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #005a00;
  --ion-color-warning-tint: #1a751a;
}




#divBodyLeft {
    height: 100%; 
    background-position: center top; 
    background-repeat: no-repeat; 
    background-size: cover;
    opacity: 0.5;
    filter: blur(3px);
}
#divBodyLeft.b1 {
    background-image: url('assets/images/backgroundSide1.png'); 
}
#divBodyLeft.b2 {
    background-image: url('assets/images/backgroundSide2.png'); 
}
#divBodyLeft.b3 {
    background-image: url('assets/images/backgroundSide3.png'); 
}
#divBodyLeft.b4 {
    background-image: url('assets/images/backgroundSide4.png'); 
}
#divBodyLeft.b5 {
    background-image: url('assets/images/backgroundSide5.png'); 
}
#divBodyLeft.b6 {
    background-image: url('assets/images/backgroundSide6.png'); 
}
#divBodyLeft.b7 {
    background-image: url('assets/images/backgroundSide7.png'); 
}

#divBodyRight {
    height: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.5;

    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
    filter: blur(3px);
}
#divBodyRight.b1 {
    background-image: url('assets/images/backgroundSide1.png'); 
}
#divBodyRight.b2 {
    background-image: url('assets/images/backgroundSide2.png'); 
}
#divBodyRight.b3 {
    background-image: url('assets/images/backgroundSide3.png'); 
}
#divBodyRight.b4 {
    background-image: url('assets/images/backgroundSide4.png'); 
}
#divBodyRight.b5 {
    background-image: url('assets/images/backgroundSide5.png'); 
}
#divBodyRight.b6 {
    background-image: url('assets/images/backgroundSide6.png'); 
}
#divBodyRight.b7 {
    background-image: url('assets/images/backgroundSide7.png'); 
}

#divBody {
    display: grid;
    grid-template-columns: 1fr 800px 1fr;
    height: 100%;
}

#divBodyAPPROOT {
    max-width: 800px;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}


ion-item.langSelectorFlag {
    --min-height: 100% !important;
    text-align: right;
}

ion-item.langSelectorFlag > ion-select {
    max-height: 1.5em;
    min-width: 7em;
    background-color: unset !important;
}

ion-item.langSelectorFlag > ion-select > ion-select-option.fr {
    background: url("/assets/icon/flags/fr.png");
    background-size: 20px;
    background-repeat: no-repeat;
    padding: 0px 27px;
}


ion-menu {
    --max-width: 450px;
}
ion-split-pane {
    max-width: 800px;
    margin: auto;
}

.noborder {
    border: 0px;
}

ion-content{
    --padding-bottom: 5em;
}

div.form-error-message {
    border-top: solid 1px #f00;
    padding-left: 1em;
    padding-top: 0.4em;
    color: #f00;
    font-size: 0.8em;
}

div.form-error-message-general {
    border-top: solid 1px #f00;
    border-bottom: solid 1px #f00;
    padding-left: 1em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    color: #f00;
    font-size: 0.8em;
}

div.form-info-message-general {
    padding-left: 1em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    color: #000;
    background-color: #ffe49a;
    font-size: 0.8em;
}


ion-input[type="file"] {
    margin-left:10em;
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

ion-textarea, ion-input, ion-select {
    background-color: rgb(242, 245, 254) !important;
    min-width: 200px;
    --padding-end: 5px !important;
    --padding-start: 0.5em !important;
}
@media (prefers-color-scheme: dark) {
  :root {
    ion-textarea, ion-input, ion-select {
        background-color: #2f2e2e !important;
    }
  }
}



ion-item > label {
    margin-right: 2em;
}

ion-item-group {
    margin-bottom: 1em;
}

ion-img.avatar, img.avatar {
    margin: 0.5em 0em 0.2em 0em;
    height: 100%;
    max-height: 3em;
}

ion-img.avatar.menu, img.avatar.menu {
    margin: 0.1em 1em 0.1em 0em;
    height: 3em;
    width: 3em;
    max-height: 3em;
}

ion-img.avatar.header, img.avatar.header {
    margin: 0 0 0 0;
    height: 70%;
}

ion-img.avatar.header_logo, img.avatar.header_logo {
    margin: 0 0 0 0;
    height: 1em;    
}




.map_notify {
    top: 0em;
    left: 0em;
    width: 100%;
    z-index: 100000;
}

ion-grid {
  --ion-grid-column-padding: 10px;
  border-collapse: collapse;
  border-style: hidden;
    
  ion-col {
    border: 1px solid black;
    border-bottom: 0;
    border-right: 0;
  }
  ion-col:last-child {
    border-right: 1px solid black;
  }
  
  ion-row:last-child {
    border-bottom: 1px solid black;
  }
}


ion-grid.noborder {
  ion-col {
    border: 0px;
  }
  ion-col:last-child {
    border: 0px;
  }
  
  ion-row:last-child {
    border: 0px;
  }
}

.timeUpdateGreen {
    color: #0F0;
}

.timeUpdateOrange {
    color: #ff7b43;
}

.timeUpdateRed {
    color: #F00;
}

.batteryStatus10 {
    color: #ff001c;
    font-weight: bold;
}

.batteryStatus20 {
    color: #ff591b;
    font-weight: bold;
}

.batteryStatus40 {
    color: #fb9632;
    font-weight: bold;
}

.batteryStatus60 {
    color: #ffe827;
    font-weight: bold;
}

.batteryStatus90 {
    color: #43b05c;
    font-weight: bold;
}

.batteryStatus100 {
    color: #43b05c;
    font-weight: bold;
}



.alertControlerChangeMode .alert-button-group {
        flex-direction: row !important;
}

.alertControllerButtonBlue {
    background-color: #0685ca !important;
    color: #fff !important;
}

table.activity-live-component tr td{
    vertical-align: middle; 
    border-left: 1px solid #000;
    text-align: center;
    color: #fff;
    background-color: #0781c3;
}

table.activity-live-component tr td.noleftborder{
    border-left: 0px solid #000;
}

table.activity-live-component tr td.bold{
    font-weight: bold;
}

ion-grid.stats {
    border: 0px;
}
ion-grid.stats ion-col {
    border: 0px;
}
ion-grid.stats ion-row {
    border: 0px;
}
ion-grid.stats ion-row.header {
    background-color: #0781c3;
    color: #fff;
    font-weight: bold;
    border: 0px;
}
ion-grid.stats ion-row.even {
    background-color: #ced4da;
}
ion-grid.stats ion-row.odd {
    background-color: #dee2e6;
}
ion-grid.stats ion-col.header {
    text-align: right;
    font-weight: bold;
}

@media (prefers-color-scheme: dark) {
  :root {
    ion-grid.stats ion-row.even {
        background-color: #7c7c7c;
    }
    ion-grid.stats ion-row.odd {
        background-color: #b5b5b5;
    }
  }
}



div.modeActionMessage {
    padding: 0.3em;
    text-align: center;
    background-color: #0685ca;
    color: #fff;
    font-weight: bold;
}

div.NetworkErrorMessage {
    padding: 0.3em;
    text-align: center;
    background-color: #F00;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
}

div.modeActionWarningMessage {
    padding: 0.3em;
    text-align: center;
    background-color: #e68f43;
    color: #fff;
    font-weight: bold;
}

.submit-enter {
  position: absolute;
  visibility: hidden;
  left: -1000px;
}

app-map .ol-unselectable.ol-layers {
  z-index: -1 !important;
}

.ol-overlaycontainer-stopevent {
    z-index: 9999999 !important;
}

.ol-control {
    z-index: 9999999 !important;
    background-color: #0781c3;
    box-shadow: 0 4px 16px rgba(0,0,0,0.12);
    transition: 0.2s transform cubic-bezier(0.25, 1.11, 0.78, 1.59);
    border-radius: 50%;
}

.ol-control:hover {
    background-color: #0781c3;
}

.ol-control:has(> :nth-child(1)) {
    border-radius: 3em;
}

.ol-message.ol-control:hover {
    background-color: rgba(255,255,255,0);
}

.ol-control button {
    background: transparent !important;
}

.ol-control button:hover {
    background: transparent !important;
}

.ol-message {
    width: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    background-color: rgba(255,255,255,0);
    margin: 0;
    padding: 0;
    z-index: 99 !important;
}

.ol-message div {
    padding-left: 3.8em;
    padding-right: 3.8em;
    font-size: 0.9em;
}

.ol-zoom {
    z-index: 99999;
    border-radius: 10px;
}

.ol-touch .ol-last-update.ol-control:hover, .ol-last-update.ol-control:hover {
    background-color: #0781c3;
    border-radius: 5px;
    font-weight: bold;
}

.ol-touch .ol-last-update, .ol-last-update {
    bottom: 1em;
    left: 7.5em;
    position: absolute;
    z-index: 100000;
    background: #fff;
    padding: 0.2em;
    font-size: 0.7em;
    background-color: #0781c3;
    border-radius: 5px;
    font-weight: bold;

    background-image: url('assets/icon/horloge_white.png');
    background-size: 1.2em 1em;
    background-repeat: no-repeat;
    background-position-y: center;
    padding-left: 1.6em;
    background-position-x: 0.2em;
}

.ol-touch .ol-altitude, .ol-altitude {
    bottom: 3em;
    left: 7.5em;
    position: absolute;
    z-index: 100000;
    background: #fff;
    padding: 0.2em;
    font-size: 0.7em;
    background-color: #0781c3;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    background-image: url('assets/icon/d_white.png');
    background-size: 1.2em 1em;
    background-repeat: no-repeat;
    background-position-y: center;
    padding-left: 1.6em;
    background-position-x: 0.2em;
}

.ol-watermark.ol-control:hover {
    background-color: rgba(255,255,255,0);
}

.ol-watermark {
    bottom: 1em;
    right: 1em;
    background-color: rgba(255,255,255,0);
}


.ol-avatar.ol-control:hover {
    background-color: rgba(255,255,255,0);
}
.ol-avatar {
    bottom: 10em;
    height: 2em;
    right: 1em;
    background-color: rgba(255,255,255,0);
}

.ol-text.ol-control:hover {
    background-color: rgba(255,255,255,0);
}
.ol-text {
    bottom: 5.5em;
    right: 1em;
    background-color: rgba(255,255,255,0);
    color: #fff;
    font-weight: bold;
}

.ol-update-position {
  top: 10px;
  right: .5em;
}
.ol-touch .ol-update-position {
  top: 10px;
  padding: 2px;
}
.ol-update-position button {
  padding: 4px;
}

.ol-tracking {
  top: 50px;
  right: .5em;
}
.ol-touch .ol-tracking {
  top: 60px;
  padding: 2px;
}
.ol-tracking button {
  padding: 4px;
}


.ol-sound {
  top: 130px;
  right: .5em;
}
.ol-touch .ol-sound {
  top: 160px;
  padding: 2px;
}
.ol-sound button {
  padding: 4px;
}


.ol-screenlock {
  top: 90px;
  right: .5em;
}
.ol-touch .ol-screenlock {
  top: 110px;
  padding: 2px;
}
.ol-screenlock button {
  padding: 4px;
}


.ol-export {
  top: 130px;
  right: .5em;
}
.ol-touch .ol-export {
  top: 160px;
  padding: 2px;
}
.ol-export button {
  padding: 4px;
}


.ol-distance.ol-control:hover {
    background-color: rgba(255,255,255,0);
}

.ol-distance {
    bottom: 2em;
    left: 0px;
    position: absolute;
    z-index: 100000;
    /* background: #fffa; */
    /* padding: 0.2em; */
    font-size: 0.6em;
    color: #fff;
    text-align: center;
    width: 90px;
    background-color: rgba(255,255,255,0);
}

.ol-canigpscompass.ol-control:hover {
    background-color: rgba(255,255,255,0);
}

.ol-canigpscompass {
  bottom: 0em;
  left: 0.3em;
  width: 5em;
  height: 5em;
  background: none;
}
.ol-touch .ol-canigpscompass {
  padding: 2px;
  background: none;
}
.ol-canigpscompass button {
  padding: 4px;
}

.ol-canigpscompass img {
    position: absolute; 
    width: 100%; 
    height: 100%; 
    top: 0; 
    left: 0;
}


.ol-layer-switch {
  top: 80px;
  left: .5em;
}

.ol-layer-switch button {
    padding: 0px;
}
.ol-layer-switch button img {
    border-radius: 50%;
}
.ol-touch .ol-layer-switch {
  top: 95px;
  padding: 2px;
}

.ol-info {
  top: 120px;
  left: .5em;
}
.ol-touch .ol-info {
  top: 145px;
  padding: 2px;
}
.ol-info button {
  padding: 4px;
}
.ol-info.notselected {
}
.ol-info.selected {
    background-color: #2dd36f;
}

.ol-clear {
  top: 160px;
  left: .5em;
  background-color: #f00;
}
.ol-touch .ol-clear {
  top: 195px;
  padding: 2px;
}

.ol-rotate {
  top: 200px;
  left: .5em;
  right: auto;
}
.ol-touch .ol-rotate {
  top: 245px;
  padding: 2px;
}
.ol-rotate button {
  padding: 4px;
}

.ol-tracking.notselected {
}
.ol-tracking.selected {
    background-color: #2dd36f;
}


.ol-screenlock.notselected {
}
.ol-screenlock.selected {
    background-color: #2dd36f;
}


.ol-attribution {
    display: none;
}

.olImageLoadError {
    display: none;
  }

.button_small {
  height: 2em;
  --vertical-align: middle;
  --padding-start: 0.2em;
  --padding-end: 0.2em;
  --padding-top: 0.2em;
  --padding-bottom: 0.2em;
}

a.bold {
    font-weight: bold;
}

.timeRangeActive {
    opacity: 1;
}
.timeRangeInActive {
    opacity: 0.3;
}

ion-tabs { contain: none; }
ion-tab-bar { contain: none }


.StatusGrid {
    text-align: center;
    border: 0;
}

.StatusGrid ion-row {
    border-bottom: 0 !important;
}

.StatusGrid ion-row ion-col {
    border-width: 0px 1px 0px 1px;
    border-collapse: collapse;
    padding: 0.3em;
    vertical-align: middle;
}

.StatusGrid ion-row ion-col:first-child {
    border-left: 0px;
}

.StatusGrid ion-row ion-col:last-child {
    border-right: 0px;
}

.StatusGrid ion-row ion-col.header {
    font-weight: bold;
    color: #65c0f6;
}

.StatusGrid ion-row ion-col ion-img.icon, .StatusGrid ion-row ion-col img.icon {
    width: 1.5em;
    margin: auto;
}

.StatusSeparator {
    height: 1em;
}

.StatusGridAvatar {
    border: 0;
    padding: 0;
    margin-bottom: 1em;
}

.StatusGridAvatar ion-row ion-col {
    border: 0;
    padding:0;
    text-align: center;
}

.StatusGridAvatar ion-row {
    border-bottom: 0 !important;
}

.statusMessageBt {
    font-size: 0.8rem;
    padding:0;
    margin: 0;
    vertical-align: top;
}




.label-small {
    font-size: 0.8em !important;
}

ion-label {
    line-height: 1.5em;
}

ion-label[position="stacked"] {
    padding-bottom: 0.7em;
}

ion-fab-button.delete::part(native) {
  background-color: red;
}

ion-fab-button.delete::part(native):hover::after {
  background-color: red;
}

ion-fab-button.delete::part(native):active::after {
  background-color: red;
}

body.scanner-active {
    display: none;
  --background: transparent;
  --ion-background-color: transparent;
}

.statusGPSDiv {
  display: inline-block;
  margin-left: 0.4em;
  margin-right: 0.4em;
  color: var(--ion-tab-bar-color, var(--ion-color-step-400, #999999));
}

.statusGPSLabel {
  margin-right: 0.5em; 
  font-size: 0.8em; 
  display: inline-block; 
  vertical-align: middle;
}

.statusNetworkLabel {
  margin-right: 0.5em;
  font-size: 0.8em;
  display: inline-block;
  vertical-align: middle;
}

.statusBatteryLabel {
  margin-right: 0.5em;
  font-size: 0.8em;
  display: inline-block;
  vertical-align: middle;
}



@mixin star-rating( $filled-color: #F9BF3B, $empty-color: #444, $size: 2em, $width: 80%) {
 $star--filled: ★;
 $star--empty: ☆;
 width: $width;
 > * {
  float: right;
 }
 // optional initial pulse of stars
 @at-root {
  @keyframes pulse {
   50% {
    color: lighten($empty-color, 10%);
    text-shadow: 0 0 15px lighten($empty-color, 20%);
   }
  }
 }
 label {
  height: 40px;
  width: 20%;
  display: block;
  position: relative;
  cursor: pointer;
  @for $i from 5 through 1 {
   &:nth-of-type(#{$i}):after {
    $animation-delay: $i * .05s;
    animation-delay: $animation-delay;
   }
  }
  &:after {
   transition: all 0.4s ease-out;
   -webkit-font-smoothing: antialiased;
   position: absolute;
   content: '#{$star--empty}';
   color: $empty-color;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   text-align: center;
   font-size: $size;
   animation: 1s pulse ease;
  }
  &:hover:after {
   color: lighten($empty-color, 10%);
   text-shadow: 0 0 15px lighten($empty-color, 10%);
  }
 }
 input {
  display: none;
  &:checked {
   + label:after,
   ~ label:after {
    content: '#{$star--filled}';
    color: $filled-color;
    text-shadow: 0 0 20px $filled-color;
   }
  }
 }
}

.rating {
    margin-top: 0.5em;
    margin-bottom: 4em;
    @include star-rating();
    margin-left: auto;
    margin-right: auto;
}

ion-title {
    max-width: fit-content;
    display: inline-flex;
}

ion-footer {
    //padding-bottom: 40px;
}

ion-modal#modal-status::part(content) {
    min-height: 90% !important;
}



ion-footer.footer-toolbar-padding ion-toolbar:last-of-type {
    padding-bottom: 0 !important;
}

ion-modal ion-footer.footer-toolbar-padding ion-toolbar:last-of-type {
    padding-bottom: var(--ion-safe-area-bottom, 0px) !important;
}


body.keyboard-is-open .canicompet-tabs {
  display: none;
}

body.keyboard-is-open ion-router-outlet {
  margin-bottom: 0px !important;
}


